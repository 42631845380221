import firebase from 'firebase'

export const verificaAutenticado = (contexto) => {

    firebase.auth().onAuthStateChanged(firebaseUser => {
        if (firebaseUser) {
            if (contexto === 'login') {
                firebase.firestore().collection('usuarios').doc(firebaseUser.uid).get().then((doc) => {
                    if (doc.data().usuario === 1) {
                        window.location = '/administrador'
                    } 
                })
            } else {

            }
        } else {
            if (contexto !== 'login') {
                window.location = '/';
            }
        }
    });
}

export const logout = () => {
    firebase.auth().signOut().then(() => {
        window.location = '/'
    })
}