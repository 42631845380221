import React, { useEffect, useState } from 'react';
import Header from '../componentes/Header'
import Sidebar from '../componentes/Sidebar'
import firebase from 'firebase'
import { buscaEndereco } from '../../../services/WsConnect'
import moment from 'moment'
import 'suneditor/dist/css/suneditor.min.css';

export default function CadastraCliente() {

    const [servicos] = useState({
        clientes: firebase.firestore().collection('clientes'),
        usuarios: firebase.firestore().collection('usuarios'),
    })
    const [usuario, setUsuario] = useState({})

    const [nome, setNome] = useState('')
    const [contato, setContato] = useState('')
    const [responsavel, setResponsavel] = useState('')
    const [cep, setCep] = useState('')
    const [endereco, setEndereco] = useState('')
    const [bairro, setBairro] = useState('')
    const [numero, setNumero] = useState('')
    const [complemento, setComplemento] = useState('')
    const [uf, setUf] = useState('')
    const [cidade, setCidade] = useState('')

    const [sucesso, setSucesso] = useState('');
    const [erro, setErro] = useState('');

    const [loading, setLoading] = useState(false)
    const [loadingCep, setLoadingCep] = useState(false)


    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            servicos.usuarios.doc(user.uid).get().then((doc) => {
                setUsuario(doc.data());
            })
        })
    }, [servicos.clientes, servicos.usuarios])

    function removeMensagem() {
        setTimeout(() => {
            setLoading(false)
            setSucesso('')
            setErro('')
            window.location = '/filiais'
        }, 3000)
    }

    function cadastraFilial() {
        setLoading(true)
        setErro('')
        if (nome === '') {
            setErro('Favor digitar o nome da Filial')
            setLoading(false)
        } else if (cep === '') {
            setErro('Favor digitar o Cep')
            setLoading(false)
        } else if (endereco === '') {
            setErro('Favor digitar o Endereço')
            setLoading(false)
        } else if (bairro === '') {
            setErro('Favor digite o Bairro')
            setLoading(false)
        } else if (complemento === '') {
            setErro('Favor digite o Complemento')
            setLoading(false)
        } else if (numero === '') {
            setErro('Favor digite o número')
            setLoading(false)
        } else {
            servicos.usuarios.doc(usuario.id).collection('filiais').add({
                nome: nome,
                contato: contato,
                responsavel: responsavel,
                endereco: {
                    endereco: endereco,
                    bairro: bairro,
                    numero: numero,
                    complemento: complemento,
                    uf: uf,
                    cidade: cidade,
                    cep: cep
                },
                status: parseInt(1),
                admin: {
                    id: usuario.id,
                    nome: usuario.nome
                },
                dataAtualizacao: moment().format('DD/MM/YYYY HH:mm')
            }).then((docRef) => {
                servicos.usuarios.doc(usuario.id).collection('filiais').doc(docRef.id).update({
                    id: docRef.id
                }).then(() => {
                    setSucesso('Filial cadastrada com sucesso')
                    removeMensagem()
                }).catch(() => {
                    setErro('Erro ao atualizar o cliente')
                })
            }).catch(() => {
                setErro('Erro ao cadastrar o Cliente')
            })
        }
    }

    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleContato = (event) => {
        event.persist();
        setContato(event.target.value);
    }
    const handleResponsavel = (event) => {
        event.persist();
        setResponsavel(event.target.value);
    }
    const handleCep = (event) => {
        event.persist();
        setCep(event.target.value);
    }
    const handleEndereco = (event) => {
        event.persist();
        setEndereco(event.target.value);
    }
    const handleBirro = (event) => {
        event.persist();
        setBairro(event.target.value);
    }
    const handleComplemento = (event) => {
        event.persist();
        setComplemento(event.target.value);
    }
    const handleNumero = (event) => {
        event.persist();
        setNumero(event.target.value);
    }
    const handleCidade = (event) => {
        event.persist();
        setCidade(event.target.value);
    }
    const handleUf = (event) => {
        event.persist();
        setUf(event.target.value);
    }

    function buscaCep() {
        setLoadingCep(true)
        if (cep.length < 8) {
            setLoadingCep(false)
            alert('Favor digite o CEP para realizar a busca')
        } else {
            buscaEndereco(cep).then((doc) => {
                if (doc.erro === true) {
                    setLoadingCep(false)
                    alert('Não achamos seu CEP! Favor verifique novamente ou digite manualmente')
                } else {
                    setEndereco(doc.logradouro)
                    setBairro(doc.bairro)
                    setCidade(doc.localidade)
                    setUf(doc.uf)
                    setLoadingCep(false)
                }
            })
        }
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div className="content">
                    {erro ?
                        <div class="alert alert-danger" role="alert">{erro}</div>
                        :
                        null
                    }
                    {sucesso ?
                        <div class="alert alert-success" role="alert">{sucesso}</div>
                        :
                        null
                    }
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Nova Filial</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--">

                                            <div className='row col-md-12' style={{ marginTop: 20 }}>
                                                <div className='col-md-4'>
                                                    <label>Nome da Filial</label>
                                                    <div class="form-group">
                                                        <input type="text" value={nome} id="company" class="form-control" onChange={handleNome} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Contato</label>
                                                    <div class="form-group">
                                                        <input type="number" value={contato} id="company" class="form-control" onChange={handleContato} />
                                                    </div>
                                                </div>
                                                <div className='col-md-5'>
                                                    <label>Responsável</label>
                                                    <div class="form-group">
                                                        <input type="text" value={responsavel} id="company" class="form-control" onChange={handleResponsavel} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row col-md-12'>
                                                <div className='col-md-3'>
                                                    <label>Cep</label>
                                                    <div class="form-group">
                                                        <input type="number" value={cep} id="company" class="form-control" onChange={handleCep} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2' style={{ marginTop: 30 }}>
                                                    {loadingCep ?
                                                        <button type="button" class="btn btn-info mb-1" >Buscando...</button>
                                                        :
                                                        <button type="button" onClick={() => buscaCep()} class="btn btn-success mb-1" >Buscar CEP</button>
                                                    }

                                                </div>
                                                <div className='col-md-7'>
                                                    <label>Endereço</label>
                                                    <div class="form-group">
                                                        <input type="text" value={endereco} id="company" class="form-control" onChange={handleEndereco} />
                                                    </div>
                                                </div>
                                                <div className='col-md-5'>
                                                    <label>Bairro</label>
                                                    <div class="form-group">
                                                        <input type="text" value={bairro} id="company" class="form-control" onChange={handleBirro} />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label>Cidade</label>
                                                    <div class="form-group">
                                                        <input type="text" value={cidade} id="company" class="form-control" onChange={handleCidade} />
                                                    </div>
                                                </div>
                                                <div className='col-md-1'>
                                                    <label>UF</label>
                                                    <div class="form-group">
                                                        <input type="text" value={uf} id="company" class="form-control" onChange={handleUf} />
                                                    </div>
                                                </div>
                                                <div className='col-md-10'>
                                                    <label>Complemento</label>
                                                    <div class="form-group">
                                                        <input type="text" value={complemento} id="company" class="form-control" onChange={handleComplemento} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>N°</label>
                                                    <div class="form-group">
                                                        <input type="number" value={numero} id="company" class="form-control" onChange={handleNumero} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="page-header col-md-3" style={{ marginTop: 20 }}>
                                                {loading ?
                                                    <button type="button" class="btn btn-default mb-1" >
                                                        Cadastrando filial ...
                                                    </button>
                                                    :
                                                    <button type="button" class="btn btn-success mb-1" onClick={() => cadastraFilial()}>
                                                        Cadastra Filial
                                                </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Header />
            </div>
        </div>

    );
}
