import React, { useEffect, useState } from 'react';
import Header from '../componentes/Header'
import Sidebar from '../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'

export default function Clientes() {

    const [conferentes, setConferentes] = useState([])
    const [servicos] = useState({
        auth: firebase.auth().currentUser,
        conferentes: firebase.firestore().collection('conferentes')
    })

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            servicos.conferentes.where('admin.id', '==', user.uid).onSnapshot((snap) => {
                const data = snap.docs.map(doc => doc.data())
                setConferentes(data)
            })
        })
    }, [servicos.conferentes])

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div class="content">

                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <div class="col-sm-10">
                                                    <h4 className="box-title">Conferentes</h4>
                                                </div>
                                                <div class="col-sm-2">
                                                    <Link to='/cadastroConferente'>
                                                        <button type="button" class="btn btn-success mb-1" >Novo Conferente</button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Avatar</th>
                                                            <th>Nome</th>
                                                            <th>Contato</th>
                                                            <th>Alocação</th>
                                                            <th>Cidade</th>
                                                            <th>UF</th>
                                                            <th>Status</th>
                                                            <th>Detalhe</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {conferentes.map((conferente) =>
                                                            <tr key={conferente.id} >
                                                                <td class="avatar">
                                                                    <div class="round-img">
                                                                        <img class="rounded-circle" src={conferente.avatar} alt="" />
                                                                    </div>
                                                                </td>
                                                                <td>{conferente.nome}</td>
                                                                <td>{conferente.contato}</td>
                                                                {conferente.alocacao.tipoAlocacao === 0 ?
                                                                    <td>
                                                                        Filial
                                                                    </td>
                                                                    :
                                                                    <td>
                                                                        Cliente
                                                                    </td>
                                                                }
                                                                <td>{conferente.endereco.cidade}</td>
                                                                <td>{conferente.endereco.uf}</td>
                                                                {conferente.status === 0 ?
                                                                    <td>
                                                                        <span className="badge badge-danger">Inativo</span>
                                                                    </td>
                                                                    :
                                                                    <td>
                                                                        <span className="badge badge-success">Ativo</span>
                                                                    </td>
                                                                }
                                                                <td>
                                                                    <Link to={{ pathname: '/detalheConferente', state: { conferente: conferente } }}>
                                                                        <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}

                                                    </tbody>
                                                </table>
                                                {conferentes.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem Conferentes cadastrados</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
