import React, { useEffect, useState } from 'react';
import Header from '../componentes/Header'
import Sidebar from '../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'

export default function Filiais() {

    const [filiais, setFiliais] = useState([])
    const [servicos] = useState({
        auth: firebase.auth().currentUser,
        usuarios: firebase.firestore().collection('usuarios')
    })

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            servicos.usuarios.doc(user.uid).collection('filiais').onSnapshot((snap) => {
                const data = snap.docs.map(doc => doc.data())
                setFiliais(data)
            })
        })
    }, [servicos.usuarios])

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div class="content">

                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <div class="col-sm-10">
                                                    <h4 className="box-title">Filiais</h4>
                                                </div>
                                                <div class="col-sm-2">
                                                    <Link to='/cadastroFilial'>
                                                        <button type="button" class="btn btn-success mb-1" >Nova Filial</button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Nome</th>
                                                            <th>Responsável</th>
                                                            <th>Contato</th>
                                                            <th>Status</th>
                                                            <th>Galpões</th>
                                                            <th>Cargueiros</th>
                                                            <th>Detalhe</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filiais.map((filial) =>
                                                            <tr key={filial.id} >
                                                                <td>{filial.nome}</td>
                                                                <td>{filial.responsavel}</td>
                                                                <td>{filial.contato}</td>
                                                                {filial.status === 0 ?
                                                                    <td>
                                                                        <span className="badge badge-danger">Inativo</span>
                                                                    </td>
                                                                    :
                                                                    <td>
                                                                        <span className="badge badge-success">Ativo</span>
                                                                    </td>
                                                                }
                                                                <td>
                                                                    <Link to={{ pathname: '/galpoesFilial', state: { filial: filial } }}>
                                                                        <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                    </Link>
                                                                </td>
                                                                <td>
                                                                    <Link to={{ pathname: '/cargueirosFilial', state: { filial: filial } }}>
                                                                        <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                    </Link>
                                                                </td>
                                                                <td>
                                                                    <Link to={{ pathname: '/detalheFilial', state: { filial: filial } }}>
                                                                        <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}

                                                    </tbody>
                                                </table>
                                                {filiais.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem filiais cadastradas</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
