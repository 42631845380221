import React, { useEffect, useState } from 'react';
import Header from '../componentes/Header'
import Sidebar from '../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'
import moment from 'moment'

export default function Filiais() {

    const [agenda, setAgenda] = useState([])
    const [usuario, setUsuario] = useState({})
    const [servicos] = useState({
        auth: firebase.auth().currentUser,
        usuarios: firebase.firestore().collection('usuarios'),
        agenda: firebase.firestore().collection('agenda'),
    })

    //Dados para cadastro e edição do Galpão
    const [id, setId] = useState('')
    const [nome, setNome] = useState('')
    const [contato, setContato] = useState('')
    const [descricao, setDescricao] = useState('')

    const [erro, setErro] = useState('')
    const [sucesso, setSucesso] = useState('')

    const [edit, setEdit] = useState(false)

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            servicos.usuarios.doc(user.uid).get().then((usuario) => {
                setUsuario(usuario.data())
            })
            servicos.agenda.where('admin.id', '==', user.uid).onSnapshot((snap) => {
                const data = snap.docs.map(doc => doc.data())
                setAgenda(data)
            })
        })
    }, [servicos.usuarios, servicos.agenda])

    function cadastraContato() {
        setErro("")
        setSucesso("")
        if (nome === '') {
            setErro('Favor digite o nome do Contato')
        } else if (contato === '') {
            setErro('Digite o número de telefone do Contato')
        } else if (descricao === '') {
            setErro('Digite uma descrição para identificar melhor o Contato')
        } else {
            if (edit) {
                servicos.agenda.doc(id).update({
                    nome: nome,
                    contato: contato,
                    descricao: descricao
                }).then(() => {
                    setSucesso('Contato atualizado com sucesso')
                    descartaDados()
                }).catch(() => {
                    setErro('Erro ao atualizar galpão')
                })
            } else {
                servicos.agenda.add({
                    nome: nome,
                    descricao: descricao,
                    contato: contato,
                    admin: {
                        id: usuario.id,
                        nome: usuario.nome
                    },
                    dataCadastro: moment().format('DD/MM/YYYY')
                }).then((docRef) => {
                    servicos.agenda.doc(docRef.id).update({
                        id: docRef.id
                    }).then(() => {
                        setSucesso('Contato cadastrado com sucesso')
                        descartaDados()
                    }).catch(() => {
                        setErro('Erro ao atualizar Contato')
                    })
                }).catch(() => {
                    setErro('Erro ao cadastrar Contato')
                })
            }
        }
    }

    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleContato = (event) => {
        event.persist();
        setContato(event.target.value);
    }
    const handleDescricao = (event) => {
        event.persist();
        setDescricao(event.target.value);
    }
    function carregaDados(id, nome,contato, descricao) {
        setEdit(true)
        setId(id)
        setNome(nome)
        setContato(contato)
        setDescricao(descricao)
    }
    function descartaDados() {
        setEdit(false)
        setId('')
        setNome('')
        setContato('')
        setDescricao('')
    }
    function removeContato(id, nome) {
        setErro("")
        setSucesso("")
        var r = window.confirm("Deseja excluir o contato " + nome + " ?");
        if (r == true) {
            servicos.agenda.doc(id).delete().then(() => {
                setSucesso("Contato removido com sucesso!!")
            }).catch(() => {
                setErro("Erro ao remover o Contato")
            })
        } else {

        }
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div class="content">

                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <div class="col-sm-10">
                                                    <h4 className="box-title">Agenda de Contatos</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className='col-md-12'>
                                                {sucesso ?
                                                    <div class="alert alert-success" role="alert">{sucesso}</div>
                                                    :
                                                    null
                                                }
                                                {erro ?
                                                    <div class="alert alert-danger" role="alert">{erro}</div>
                                                    :
                                                    null
                                                }
                                            </div>
                                            {edit ?
                                                <div class="row m-0">
                                                    <div className='col-md-3'>
                                                        <label>Nome do Contato</label>
                                                        <div class="form-group">
                                                            <input type="text" value={nome} id="company" class="form-control" onChange={handleNome} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-2'>
                                                        <label>Contato</label>
                                                        <div class="form-group">
                                                            <input type="text" value={contato} id="company" class="form-control" onChange={handleContato} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-5'>
                                                        <label>Descrição</label>
                                                        <div class="form-group">
                                                            <input type="text" value={descricao} id="company" class="form-control" onChange={handleDescricao} />
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-2" style={{ marginTop: 30 }}>
                                                        <button type="button" onClick={() => cadastraContato()} class="btn btn-success mb-1" >Atualizar Contato</button>
                                                    </div>
                                                    <div class="col-sm-2">
                                                        <button type="button" onClick={() => descartaDados()} class="btn btn-danger mb-1" >Descartar</button>
                                                    </div>
                                                </div>
                                                :
                                                <div class="row m-0">
                                                    <div className='col-md-3'>
                                                        <label>Nome do Contato</label>
                                                        <div class="form-group">
                                                            <input type="text" value={nome} id="company" class="form-control" onChange={handleNome} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-2'>
                                                        <label>Contato</label>
                                                        <div class="form-group">
                                                            <input type="text" value={contato} id="company" class="form-control" onChange={handleContato} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-5'>
                                                        <label>Descrição do Contato</label>
                                                        <div class="form-group">
                                                            <input type="text" value={descricao} id="company" class="form-control" onChange={handleDescricao} />
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-2" style={{ marginTop: 30 }}>
                                                        <button type="button" onClick={() => cadastraContato()} class="btn btn-success mb-1" >Cadastrar Contato</button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Nome</th>
                                                            <th>Contato</th>
                                                            <th>Descricao</th>
                                                            <th>Editar</th>
                                                            <th>Excluir</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {agenda.map((agenda) =>
                                                            <tr key={agenda.id} >
                                                                <td>{agenda.nome}</td>
                                                                <td>{agenda.contato}</td>
                                                                <td>{agenda.descricao}</td>
                                                                <td>
                                                                    <span onClick={() => carregaDados(agenda.id, agenda.nome,agenda.contato, agenda.descricao)} className="badge badge-warning"><i className='fa fa-edit'></i></span>
                                                                </td>
                                                                <td>
                                                                    <span onClick={() => removeContato(agenda.id, agenda.nome)} className="badge badge-danger"><i className='fa fa-trash'></i></span>
                                                                </td>
                                                            </tr>
                                                        )}

                                                    </tbody>
                                                </table>
                                                {agenda.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem contatos cadastrados</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
