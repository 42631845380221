import React, { useEffect, useState } from 'react';
import Header from '../componentes/Header'
import Sidebar from '../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'

export default function Home() {

    const [listaServicos, setServicos] = useState([])
    const [servicos] = useState({
        servicos: firebase.firestore().collection('servicos')
    })

    useEffect(() => {
        servicos.servicos.onSnapshot((snap) => {
            const data = snap.docs.map(doc => doc.data())
            setServicos(data)
        })
    }, [servicos.listaServicos])

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="row">
                            {/* <div className="col-lg-3 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="stat-widget-five">
                                            <div className="stat-icon dib flat-color-1">
                                                <i className="pe-7s-id"></i>
                                            </div>
                                            <div className="stat-content">
                                                <div className="text-left dib">
                                                    <div className="stat-text">{professores.length}</div>
                                                    <div className="stat-heading">Clientes</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="stat-widget-five">
                                            <div className="stat-icon dib flat-color-2">
                                                <i className="pe-7s-users"></i>
                                            </div>
                                            <div className="stat-content">
                                                <div className="text-left dib">
                                                    <div className="stat-text">{alunos.length}</div>
                                                    <div className="stat-heading">Motoristas</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="stat-widget-five">
                                            <div className="stat-icon dib flat-color-3">
                                                <i className="pe-7s-display2"></i>
                                            </div>
                                            <div className="stat-content">
                                                <div className="text-left dib">
                                                    <div className="stat-text">{aulas.length}</div>
                                                    <div className="stat-heading">Filiais</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            <div className="col-lg-3 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="stat-widget-five">
                                            <div className="stat-icon dib flat-color-3">
                                                <i className="pe-7s-note2"></i>
                                            </div>
                                            <div className="stat-content">
                                                <div className="text-left dib">
                                                    <div className="stat-text">{matriculas.length}</div>
                                                    <div className="stat-heading">Serviços</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-4">
                                                    <h4 className="box-title">Últimos Serviços</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Minuta</th>
                                                            <th>Serviço</th>
                                                            <th>Origem</th>
                                                            <th>Conferente</th>
                                                            <th>Motorista</th>
                                                            <th>Cargueiro</th>
                                                            <th>Placa</th>
                                                            <th>Situação</th>
                                                            <th>Ação</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {listaServicos.map((servico) =>
                                                            <tr key={servico.id} >
                                                                <td>N° {servico.minuta}</td>
                                                                <td>{servico.tipo}</td>
                                                                <td>{servico.alocacao.nome}</td>
                                                                <td>{servico.conferente.nome}</td>
                                                                <td>{servico.motorista.nome}</td>
                                                                <td>{servico.cargueiro.cargueiro}</td>
                                                                <td>{servico.cargueiro.placa}</td>
                                                                {!servico.horaCarga ?
                                                                    <td>Início da Carga</td>
                                                                    : !servico.horaTermino ?
                                                                        <td>Em carregamento</td>
                                                                        : !servico.horaSaida ?
                                                                            <td>Aguardando saída</td>
                                                                            :
                                                                            <td>Em trânsito</td>
                                                                }
                                                                <td>
                                                                    <Link to={{ pathname: '/servico', state: { servico: servico } }}>
                                                                        <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}

                                                    </tbody>
                                                </table>
                                                {servicos.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem serviços aqui</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
