import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom'

// Autenticacao
import Login from './modulos/autenticacao/Login'

// Administração
import Administracao from './modulos/administracao/home/Home'

import Clientes from './modulos/administracao/clientes/Clientes'
import CadastroCliente from './modulos/administracao/clientes/CadastroCliente'
import DetalheCliente from './modulos/administracao/clientes/DetalheCliente'
import GalpoesCliente from './modulos/administracao/clientes/GalpoesCliente'
import ConferentesClientes from './modulos/administracao/clientes/ConferentesClientes'

import Conferentes from './modulos/administracao/conferentes/Conferentes'
import CadastroConferente from './modulos/administracao/conferentes/CadastroConferente'
import DetalheConferente from './modulos/administracao/conferentes/DetalheConferente'

import Filiais from './modulos/administracao/filial/Filiais'
import CadastroFilial from './modulos/administracao/filial/CadastroFilial'
import GalpoesFilial from './modulos/administracao/filial/GalpoesFilial'
import CargueirosFilial from './modulos/administracao/filial/CargueirosFilial'
import DetalheFilial from './modulos/administracao/filial/DetalheFilial'

import Cargueiros from './modulos/administracao/cargueiros/Cargueiros'
import CadastroCargueiro from './modulos/administracao/cargueiros/CadastroCargueiro'
import DetalheCargueiro from './modulos/administracao/cargueiros/DetalheCargueiro'

import Motoristas from './modulos/administracao/motoristas/Motoristas'
import CadastroMotorista from './modulos/administracao/motoristas/CadastroMotorista'
import DetalheMotorista from './modulos/administracao/motoristas/DetalheMotorista'

// Serviços
import DetalheServico from './modulos/administracao/servicos/DetalheServico'

// Agenda
import ListaAgenda from './modulos/administracao/agenda/ListaAgenda'

export default class Routes extends Component {
    render() {
        return (
            <div className="App">
                <BrowserRouter>

                    {/* Autenticação */}
                    <Route path="/" exact={true} component={Login} />
                    
                    {/* Administração */}
                    <Route path="/administrador" exact={true} component={Administracao} />

                    <Route path="/clientes" exact={true} component={Clientes} />
                    <Route path="/cadastroCliente" exact={true} component={CadastroCliente} />
                    <Route path="/detalheCliente" exact={true} component={DetalheCliente} />
                    <Route path="/galpoesCliente" exact={true} component={GalpoesCliente} />
                    <Route path="/conferentesClientes" exact={true} component={ConferentesClientes} />
                    
                    <Route path="/conferentes" exact={true} component={Conferentes} />
                    <Route path="/cadastroConferente" exact={true} component={CadastroConferente} />
                    <Route path="/detalheConferente" exact={true} component={DetalheConferente} />
                    
                    <Route path="/filiais" exact={true} component={Filiais} />
                    <Route path="/cadastroFilial" exact={true} component={CadastroFilial} />
                    <Route path="/galpoesFilial" exact={true} component={GalpoesFilial} />
                    <Route path="/cargueirosFilial" exact={true} component={CargueirosFilial} />
                    <Route path="/detalheFilial" exact={true} component={DetalheFilial} />

                    <Route path="/cargueiros" exact={true} component={Cargueiros} />
                    <Route path="/cadastroCargueiro" exact={true} component={CadastroCargueiro} />
                    <Route path="/detalheCargueiro" exact={true} component={DetalheCargueiro} />

                    <Route path="/motoristas" exact={true} component={Motoristas} />
                    <Route path="/cadastroMotorista" exact={true} component={CadastroMotorista} />
                    <Route path="/detalheMotorista" exact={true} component={DetalheMotorista} />

                    <Route path="/servico" exact={true} component={DetalheServico} />

                    {/* Agenda */}
                    <Route path="/agenda" exact={true} component={ListaAgenda} />

                </BrowserRouter>
            </div>
        );
    }
}