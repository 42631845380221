import React, { useEffect, useState } from 'react';
import Header from '../componentes/Header'
import Sidebar from '../componentes/Sidebar'
import firebase from 'firebase'
import { buscaEndereco } from '../../../services/WsConnect'
import moment from 'moment'
import 'suneditor/dist/css/suneditor.min.css';

export default function CadastraCliente() {

    const [servicos] = useState({
        auth: firebase.auth().currentUser,
        clientes: firebase.firestore().collection('clientes'),
        usuarios: firebase.firestore().collection('usuarios'),
        motoristas: firebase.firestore().collection('motoristas'),
        pendencia: firebase.firestore().collection('pendencia'),
        conferentes: firebase.firestore().collection('conferentes'),
    })
    const [usuario, setUsuario] = useState({})

    const [nome, setNome] = useState('')
    const [contato, setContato] = useState('')
    const [cpf, setCpf] = useState('')
    const [cnh, setCNH] = useState('')
    const [cep, setCep] = useState('')
    const [endereco, setEndereco] = useState('')
    const [bairro, setBairro] = useState('')
    const [numero, setNumero] = useState('')
    const [complemento, setComplemento] = useState('')
    const [uf, setUf] = useState('')
    const [cidade, setCidade] = useState('')

    var metadata = { contentType: 'image/jpeg' };
    const [avatar, setAvatar] = useState('');
    const [upload, setUpload] = useState('');
    const [progresso, setProgresso] = useState('')

    const [sucesso, setSucesso] = useState('');
    const [erro, setErro] = useState('');

    const [loading, setLoading] = useState(false)
    const [loadingImage, setLoadingImage] = useState(false)
    const [loadingCep, setLoadingCep] = useState(false)
    const [verificaCpf, setVerificaCpf] = useState(false)
    const [loadingCpf, setLoadingCpf] = useState(false)


    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            servicos.usuarios.doc(user.uid).get().then((doc) => {
                setUsuario(doc.data());
            })
        })
    }, [servicos.clientes, servicos.usuarios])

    function removeMensagem() {
        setTimeout(() => {
            setLoading(false)
            setSucesso('')
            setErro('')
            window.location = '/motoristas'
        }, 3000)
    }

    function cadastraAvatar(id) {
        setLoadingImage(true)
        var uploadTask = firebase.storage().ref().child('motoristas/' + id).put(avatar[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgresso(parseInt(progress) + '%')
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    setLoadingImage(false)
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.motoristas.doc(id).update({
                    avatar: downloadURL
                }).then(() => {
                    setSucesso('Motorista cadastrado com sucesso! Você será redirecionado a lista de Motoristas')
                    setLoadingImage(false)
                    removeMensagem()
                })
            });
        });
    }

    function cadastraCliente() {
        setLoading(true)
        setErro('')
        if (avatar === '') {
            setErro('Favor escolha a imagem do Motorista')
            setLoading(false)
        } else if (nome === '') {
            setErro('Favor digitar o nome do Motorista')
            setLoading(false)
        } else if (contato === '' || contato.length < 11) {
            setErro('Contato vazio ou incompleto')
            setLoading(false)
        } else if (cpf === '' || cpf.length < 11) {
            setErro('CPF vazio ou incompleto')
            setLoading(false)
        } else if (verificaCpf === 1) {
            setErro('CPF já existe no sistema')
            setLoading(false)
        } else if (cnh === '' || cnh.length < 12) {
            setErro('CNH vazio ou incompleto')
            setLoading(false)
        } else if (cep === '') {
            setErro('Favor digitar o Cep')
            setLoading(false)
        } else if (endereco === '') {
            setErro('Favor digitar o Endereço')
            setLoading(false)
        } else if (bairro === '') {
            setErro('Favor digite o Bairro')
            setLoading(false)
        } else if (complemento === '') {
            setErro('Favor digite o Complemento')
            setLoading(false)
        } else if (numero === '') {
            setErro('Favor digite o número')
            setLoading(false)
        } else {
            servicos.motoristas.add({
                nome: nome,
                contato: contato,
                cpf: cpf,
                cnh: cnh,
                endereco: {
                    endereco: endereco,
                    bairro: bairro,
                    numero: numero,
                    complemento: complemento,
                    uf: uf,
                    cidade: cidade,
                    cep: cep
                },
                status: parseInt(0),
                admin: {
                    id: usuario.id,
                    nome: usuario.nome
                },
                dataAtualizacao: moment().format('DD/MM/YYYY HH:mm')
            }).then((docRef) => {
                servicos.motoristas.doc(docRef.id).update({
                    id: docRef.id
                }).then(() => {
                    servicos.pendencia.doc(docRef.id).set({
                        nome: nome,
                        contato: contato,
                        cpf: cpf,
                        id: docRef.id,
                        usuario: 'motorista'
                    })
                    cadastraAvatar(docRef.id)
                }).catch(() => {
                    setErro('Erro ao atualizar o motorista')
                })
            }).catch(() => {
                setErro('Erro ao cadastrar o Motorista')
            })
        }
    }

    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleContato = (event) => {
        event.persist();
        setContato(event.target.value);
    }
    const handleCpf = (event) => {
        event.persist();
        setCpf(event.target.value);
        if (event.target.value.length === 11) {
            setLoadingCpf(true)
            servicos.conferentes.where('cpf', '==', event.target.value).onSnapshot((snap) => {
                if (snap.size > 0) {
                    setLoadingCpf(false)
                    setVerificaCpf(1)
                } else {
                    servicos.motoristas.where('cpf', '==', event.target.value).onSnapshot((snapMoto) => {
                        if (snapMoto.size > 0) {
                            setLoadingCpf(false)
                            setVerificaCpf(1)
                        } else {
                            setLoadingCpf(false)
                            setVerificaCpf(0)
                        }
                    })
                }
            })
        } else {
            setVerificaCpf(false)
        }
    }
    const handleCNH = (event) => {
        event.persist();
        setCNH(event.target.value);
    }
    const handleCep = (event) => {
        event.persist();
        setCep(event.target.value);
    }
    const handleEndereco = (event) => {
        event.persist();
        setEndereco(event.target.value);
    }
    const handleBirro = (event) => {
        event.persist();
        setBairro(event.target.value);
    }
    const handleComplemento = (event) => {
        event.persist();
        setComplemento(event.target.value);
    }
    const handleNumero = (event) => {
        event.persist();
        setNumero(event.target.value);
    }
    const handleCidade = (event) => {
        event.persist();
        setCidade(event.target.value);
    }
    const handleUf = (event) => {
        event.persist();
        setUf(event.target.value);
    }
    const handleAvatar = (event) => {
        event.persist();
        setAvatar(event.target.files)
        setUpload(URL.createObjectURL(event.target.files[0]))
    }

    function buscaCep() {
        setLoadingCep(true)
        if (cep.length < 8) {
            setLoadingCep(false)
            alert('Favor digite o CEP para realizar a busca')
        } else {
            buscaEndereco(cep).then((doc) => {
                if (doc.erro === true) {
                    setLoadingCep(false)
                    alert('Não achamos seu CEP! Favor verifique novamente ou digite manualmente')
                } else {
                    setEndereco(doc.logradouro)
                    setBairro(doc.bairro)
                    setCidade(doc.localidade)
                    setUf(doc.uf)
                    setLoadingCep(false)
                }
            })
        }
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div className="content">
                    {erro ?
                        <div class="alert alert-danger" role="alert">{erro}</div>
                        :
                        null
                    }
                    {sucesso ?
                        <div class="alert alert-success" role="alert">{sucesso}</div>
                        :
                        null
                    }
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Novo Motorista</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className='col-md-12 row' >
                                                <div className='col-md-4'>
                                                    {upload ?
                                                        <img class="card-img-top" style={{ height: 300, width: 300 }} src={upload} alt='carregando...' />
                                                        :
                                                        <img class="card-img-top" style={{ height: 300, width: 300 }} src='https://img2.gratispng.com/20180315/sqq/kisspng-cartoon-truck-driver-cartoon-painted-the-wagon-man-5aa9fc19dc24b2.9381919315210895619017.jpg' alt='carregando...' />
                                                    }

                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Mudar a imagem do Motorista</label>
                                                    <p>Para mudar a foto do motorista é só carregar uma nova imagem e enviar. Automaticamente ele já estará salvo</p>

                                                    {loadingImage ?
                                                        <div class="progress-box progress-1" style={{ marginTop: 10 }}>
                                                            <div class="por-txt">Carregando... ({progresso})</div>
                                                            <div class="progress mb-2" >
                                                                <div class="progress-bar bg-flat-color-1" role="progressbar" style={{ width: progresso }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <input onChange={handleAvatar} type="file" name="name" placeholder="Escolha a imagem " />
                                                    }

                                                </div>
                                            </div>

                                            <div className='row col-md-12' style={{ marginTop: 20 }}>
                                                <div className='col-md-4'>
                                                    <label>Nome do Motorista</label>
                                                    <div class="form-group">
                                                        <input type="text" value={nome} id="company" class="form-control" onChange={handleNome} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Contato</label>
                                                    <div class="form-group">
                                                        <input type="number" value={contato} id="company" class="form-control" onChange={handleContato} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>CPF</label>
                                                    <div class="form-group">
                                                        <input type="number" value={cpf} id="company" class="form-control" onChange={handleCpf} />
                                                    </div>
                                                    {loadingCpf ?
                                                        <p style={{ color: "blue", fontSize: 10 }}>Aguarde.. Verifcando CPF no sistema </p>
                                                        :
                                                        null
                                                    }
                                                    {verificaCpf === 1 ?
                                                        <p style={{ color: "red", fontSize: 10 }}>CPF já cadastrado </p>
                                                        : verificaCpf === 0 ?
                                                            <p style={{ color: "green", fontSize: 10 }}>CPF disponível </p>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>CNH</label>
                                                    <div class="form-group">
                                                        <input type="number" value={cnh} id="company" class="form-control" onChange={handleCNH} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row col-md-12'>
                                                <div className='col-md-3'>
                                                    <label>Cep</label>
                                                    <div class="form-group">
                                                        <input type="number" value={cep} id="company" class="form-control" onChange={handleCep} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2' style={{ marginTop: 30 }}>
                                                    {loadingCep ?
                                                        <button type="button" class="btn btn-info mb-1" >Buscando...</button>
                                                        :
                                                        <button type="button" onClick={() => buscaCep()} class="btn btn-success mb-1" >Buscar CEP</button>
                                                    }

                                                </div>
                                                <div className='col-md-7'>
                                                    <label>Endereço</label>
                                                    <div class="form-group">
                                                        <input type="text" value={endereco} id="company" class="form-control" onChange={handleEndereco} />
                                                    </div>
                                                </div>
                                                <div className='col-md-5'>
                                                    <label>Bairro</label>
                                                    <div class="form-group">
                                                        <input type="text" value={bairro} id="company" class="form-control" onChange={handleBirro} />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label>Cidade</label>
                                                    <div class="form-group">
                                                        <input type="text" value={cidade} id="company" class="form-control" onChange={handleCidade} />
                                                    </div>
                                                </div>
                                                <div className='col-md-1'>
                                                    <label>UF</label>
                                                    <div class="form-group">
                                                        <input type="text" value={uf} id="company" class="form-control" onChange={handleUf} />
                                                    </div>
                                                </div>
                                                <div className='col-md-10'>
                                                    <label>Complemento</label>
                                                    <div class="form-group">
                                                        <input type="text" value={complemento} id="company" class="form-control" onChange={handleComplemento} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>N°</label>
                                                    <div class="form-group">
                                                        <input type="number" value={numero} id="company" class="form-control" onChange={handleNumero} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="page-header col-md-3" style={{ marginTop: 20 }}>
                                                {loading ?
                                                    <button type="button" class="btn btn-default mb-1" >
                                                        Cadastrando motorista ...
                                                    </button>
                                                    :
                                                    <button type="button" class="btn btn-success mb-1" onClick={() => cadastraCliente()}>
                                                        Cadastra Motorista
                                                </button>
                                                }
                                            </div>
                                            <div className='col-md-12'>

                                                {sucesso ?
                                                    <div class="alert alert-success" role="alert">{sucesso}</div>
                                                    :
                                                    null
                                                }
                                                {erro ?
                                                    <div class="alert alert-danger" role="alert">{erro}</div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Header />
            </div>
        </div>

    );
}
