import React, { useEffect, useState } from 'react';
import Header from '../componentes/Header'
import Sidebar from '../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'

export default function Home(props) {
    const [servico] = useState(props.location.state.servico)
    const [servicos] = useState({
        carregamento: firebase.firestore().collection('servicos').doc(servico.id).collection('carregamento'),
        destinos: firebase.firestore().collection('servicos').doc(servico.id).collection('destinos')
    })
    const [carregamentos, setCarregamentos] = useState([])
    const [destinos, setDestinos] = useState([])

    useEffect(() => {
        servicos.carregamento.onSnapshot((snap) => {
            const data = snap.docs.map(doc => doc.data())
            setCarregamentos(data)
        })
        servicos.destinos.onSnapshot((snap) => {
            const data = snap.docs.map(doc => doc.data())
            setDestinos(data)
        })
    }, [servicos.carregamento, servicos.destinos])

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-4">
                                                    <h3>Dados do documento</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row m-0">
                                                {servico.imagem ?
                                                    <div className="col-sm-4">
                                                        <div class="round-img">
                                                            <img style={{ width: 150, height: 150 }} src={servico.imagem} alt="" />
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="col-sm-4">
                                                        <div class="round-img">
                                                            <img style={{ width: 150, height: 150 }} src={"https://www.policiajudiciaria.pt/wp-content/uploads/2004/04/sem-foto.jpg"} alt="" />
                                                        </div>
                                                    </div>
                                                }

                                                <div className="col-sm-4">
                                                    <h1 className="box-title">Minuta</h1>
                                                    <p >N°{servico.minuta}</p>
                                                </div>
                                                <div className="col-sm-4">
                                                    <h1 className="box-title">Tipo de Serviço</h1>
                                                    <p >{servico.tipo}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-4">
                                                    <h3>Informações da Carga</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-3">
                                                    <h1 className="box-title">Cargueiro</h1>
                                                    <p >{servico.cargueiro.cargueiro}</p>
                                                </div>
                                                <div className="col-sm-3">
                                                    <h1 className="box-title">Placa</h1>
                                                    <p >{servico.cargueiro.placa}</p>
                                                </div>
                                                <div className="col-sm-3">
                                                    <h1 className="box-title">Modelo</h1>
                                                    <p >{servico.cargueiro.modelo}</p>
                                                </div>
                                                <div className="col-sm-3">
                                                    <h1 className="box-title">Cor</h1>
                                                    <p >{servico.cargueiro.cor}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-4">
                                                    <h3>Dados do conferente</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-3">
                                                    <div class="round-img">
                                                        <img style={{ width: 150, height: 150 }} src={servico.conferente.avatar} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <h1 className="box-title">Nome</h1>
                                                    <p >{servico.conferente.nome}</p>
                                                </div>
                                                <div className="col-sm-3">
                                                    <h1 className="box-title">Contato</h1>
                                                    <p >{servico.conferente.contato}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-4">
                                                    <h3>Dados do Motorista</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-3">
                                                    <div class="round-img">
                                                        <img style={{ width: 150, height: 150 }} src={servico.motorista.avatar} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <h1 className="box-title">Nome</h1>
                                                    <p >{servico.motorista.nome}</p>
                                                </div>
                                                <div className="col-sm-3">
                                                    <h1 className="box-title">Contato</h1>
                                                    <p >{servico.motorista.contato}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-4">
                                                    <h3>Informações de Horário</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-3">
                                                    <h1 className="box-title">Chegada</h1>
                                                    <p >{servico.horaChegada} hs</p>
                                                </div>
                                                <div className="col-sm-3">
                                                    <h1 className="box-title">Início</h1>
                                                    {servico.horaCarga ?
                                                        <p >{servico.horaCarga} hs</p>
                                                        :
                                                        <p >--:--</p>
                                                    }
                                                </div>
                                                <div className="col-sm-3">
                                                    <h1 className="box-title">Término</h1>
                                                    {servico.horaTermino ?
                                                        <p >{servico.horaTermino} hs</p>
                                                        :
                                                        <p >--:--</p>
                                                    }
                                                </div>
                                                <div className="col-sm-3">
                                                    <h1 className="box-title">Saída</h1>
                                                    {servico.horaSaida ?
                                                        <p >{servico.horaSaida} hs</p>
                                                        :
                                                        <p >--:--</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row m-0">
                                                <div className="col-sm-6">
                                                    <h3>Informações do Carregamento</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Galpão</th>
                                                            <th>Descrição</th>
                                                            <th>Plataforma</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {carregamentos.map((carregamento) =>
                                                            <tr key={carregamento.id} >
                                                                <td>{carregamento.galpao.nome}</td>
                                                                <td>{carregamento.galpao.descricao}</td>
                                                                <td>{carregamento.plataforma.nome}</td>
                                                            </tr>
                                                        )}

                                                    </tbody>
                                                </table>
                                                {carregamentos.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem informações aqui</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {servico.tipo === "ABA" ?
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row m-0">
                                                    <div className="col-sm-6">
                                                        <h3>Destinos</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="table-stats order-table ov-h">
                                                    <table className="table ">
                                                        <thead>
                                                            <tr>
                                                                <th>Imagem</th>
                                                                <th>Destino</th>
                                                                <th>Hora de Chegada</th>
                                                                <th>Situação</th>
                                                                <th>Ver imagem</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {destinos.map((destino) =>
                                                                <tr key={destino.id} >
                                                                    {destino.imagem ?
                                                                        <td class="avatar">
                                                                            <div class="round-img">
                                                                                <img class="rounded-circle" src={destino.imagem} alt="" />
                                                                            </div>
                                                                        </td>
                                                                        :
                                                                        <td class="avatar">
                                                                            <div class="round-img">
                                                                                <img class="rounded-circle" src={"https://www.policiajudiciaria.pt/wp-content/uploads/2004/04/sem-foto.jpg"} alt="" />
                                                                            </div>
                                                                        </td>
                                                                    }
                                                                    <td>{destino.destino}</td>
                                                                    {destino.chegada ?
                                                                        <td>{destino.chegada} hs</td>
                                                                        :
                                                                        <td>--:--</td>
                                                                    }
                                                                    {destino.status === 1 ?
                                                                        <td>Entregue</td>
                                                                        :
                                                                        <td>Não Entregue</td>
                                                                    }
                                                                    {destino.imagem ?
                                                                        <td>
                                                                            <a href={destino.imagem} target="_blank">
                                                                                <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                            </a>
                                                                        </td>
                                                                        :
                                                                        <td>
                                                                            <span className="badge badge-danger"><i className='fa fa-close'></i></span>
                                                                        </td>
                                                                    }
                                                                </tr>
                                                            )}

                                                        </tbody>
                                                    </table>
                                                    {destinos.length === 0 ?
                                                        <p style={{ textAlign: 'center' }} >Sem informações aqui</p>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
