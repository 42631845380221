import React, { useEffect, useState } from 'react';
import Header from '../componentes/Header'
import Sidebar from '../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'

export default function Cargueiros() {

    const [usuario, setUsuario] = useState('')
    const [cargueiros, setCargueiros] = useState([])
    const [servicos] = useState({
        auth: firebase.auth().currentUser,
        cargueiros: firebase.firestore().collection('cargueiros'),
    })

    const [filiais, setFiliais] = useState([])

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            servicos.cargueiros.where('admin.id','==',user.uid).onSnapshot((snap) => {
                const data = snap.docs.map(doc => doc.data())
                setCargueiros(data)
            })
        })
    }, [servicos.cargueiros])

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div class="content">

                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <div class="col-sm-10">
                                                    <h4 className="box-title">Cargueiros</h4>
                                                </div>
                                                <div class="col-sm-2">
                                                    <Link to='/cadastroCargueiro'>
                                                        <button type="button" class="btn btn-success mb-1" >Novo Cargueiro</button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Tipo</th>
                                                            <th>Placa</th>
                                                            <th>Marca</th>
                                                            <th>Modelo</th>
                                                            <th>Cor</th>
                                                            <th>Status</th>
                                                            <th>Detalhe</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {cargueiros.map((cargueiro) =>
                                                            <tr key={cargueiro.id} >
                                                                <td>{cargueiro.cargueiro}</td>
                                                                <td>{cargueiro.placa}</td>
                                                                <td>{cargueiro.marca}</td>
                                                                <td>{cargueiro.modelo}</td>
                                                                <td>{cargueiro.cor}</td>
                                                                {cargueiro.status === 0 ?
                                                                    <td>
                                                                        <span className="badge badge-danger">Inativo</span>
                                                                    </td>
                                                                    :
                                                                    <td>
                                                                        <span className="badge badge-success">Ativo</span>
                                                                    </td>
                                                                }
                                                                <td>
                                                                    <Link to={{ pathname: '/detalheCargueiro', state: { cargueiro: cargueiro } }}>
                                                                        <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}

                                                    </tbody>
                                                </table>
                                                {cargueiros.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem cargueiros cadastradas</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
