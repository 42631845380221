import React, { useEffect, useState } from 'react';
import Header from '../componentes/Header'
import Sidebar from '../componentes/Sidebar'
import firebase from 'firebase'
import moment from 'moment'
import 'suneditor/dist/css/suneditor.min.css';

export default function DetalheCargueiro(props) {
    const [cargueiro] = useState(props.location.state.cargueiro)
    const [servicos] = useState({
        cargueiros: firebase.firestore().collection('cargueiros'),
        usuarios: firebase.firestore().collection('usuarios'),
    })
    const [usuario, setUsuario] = useState({})
    const [filiais, setFilial] = useState([])

    const [tipoCarro, setTipoCarro] = useState(cargueiro.cargueiro)
    const [modelo, setModelo] = useState(cargueiro.modelo)
    const [marca, setMarca] = useState(cargueiro.marca)
    const [cor, setCor] = useState(cargueiro.cor)
    const [placa, setPlaca] = useState(cargueiro.placa)
    const [status, setStatus] = useState(cargueiro.status)
    const [alocacao, setAlocacao] = useState(cargueiro.filial)

    const [sucesso, setSucesso] = useState('');
    const [erro, setErro] = useState('');

    const [loading, setLoading] = useState(false)


    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            servicos.usuarios.doc(user.uid).get().then((doc) => {
                setUsuario(doc.data());
            })
            servicos.usuarios.doc(user.uid).collection('filiais').onSnapshot((snap) => {
                const data = snap.docs.map(doc => doc.data())
                setFilial(data)
            })
        })
    }, [servicos.usuarios])

    function removeMensagem() {
        setTimeout(() => {
            setLoading(false)
            setSucesso('')
            setErro('')
            window.location = '/cargueiros'
        }, 3000)
    }

    function cadastraFilial() {
        setLoading(true)
        setErro('')
        if (tipoCarro === '') {
            setErro('Favor escolha o tipo do Cargueiro')
            setLoading(false)
        } else if (modelo === '') {
            setErro('Favor digitar o Modelo')
            setLoading(false)
        } else if (marca === '') {
            setErro('Favor digitar a Marca')
            setLoading(false)
        } else if (cor === '') {
            setErro('Favor digite a cor')
            setLoading(false)
        } else if (placa === '' || placa.length < 7) {
            setErro('Placa incompleta ou vazia')
            setLoading(false)
        } else if (status === '') {
            setErro('Escolha entre ativo ou inativo')
            setLoading(false)
        } else if (alocacao === '') {
            setErro('Escolha a filial para qual o cargueiro')
            setLoading(false)
        } else {
            servicos.cargueiros.doc(cargueiro.id).update({
                cargueiro: tipoCarro,
                modelo: modelo,
                marca: marca,
                cor: cor,
                placa: placa,
                filial: alocacao,
                status: parseInt(status),
                dataAtualizacao: moment().format('DD/MM/YYYY HH:mm'),
                dataCadastro: moment().format('DD/MM/YYYY HH:mm'),
            }).then((docRef) => {
                setSucesso('Cargueiro atualizado com sucesso')
                removeMensagem()
            }).catch(() => {
                setErro('Erro ao cadastrar o Cargueiro')
            })
        }
    }

    const handleTipoCarro = (event) => {
        event.persist();
        setTipoCarro(event.target.value);
    }
    const handleModelo = (event) => {
        event.persist();
        setModelo(event.target.value);
    }
    const handleMarca = (event) => {
        event.persist();
        setMarca(event.target.value);
    }
    const handleCor = (event) => {
        event.persist();
        setCor(event.target.value);
    }
    const handlePlaca = (event) => {
        event.persist();
        setPlaca(event.target.value);
    }
    const handleStatus = (event) => {
        event.persist();
        setStatus(event.target.value);
    }
    const handleAlocacao = (event) => {
        event.persist();
        console.log(event.target.value)
        setAlocacao(event.target.value);
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div className="content">
                    {erro ?
                        <div class="alert alert-danger" role="alert">{erro}</div>
                        :
                        null
                    }
                    {sucesso ?
                        <div class="alert alert-success" role="alert">{sucesso}</div>
                        :
                        null
                    }
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Novo Cargueiro</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--">

                                            <div className='row col-md-12' style={{ marginTop: 20 }}>
                                                <div className='col-md-3'>
                                                    <label>Tipo Cargueiro</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleTipoCarro} >
                                                        <option value="">Escolha o tipo </option>
                                                        <option value="Carro" selected={cargueiro.cargueiro === "Carro" ? "selected" : null}>Carro Popular </option>
                                                        <option value="Caminhão" selected={cargueiro.cargueiro === "Caminhão" ? "selected" : null}>Caminhão </option>
                                                        <option value="Furgão" selected={cargueiro.cargueiro === "Fugão" ? "selected" : null}>Furgão </option>
                                                        <option value="Caminhonete" selected={cargueiro.cargueiro === "Caminhonete" ? "selected" : null}>Caminhonete </option>
                                                    </select>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Modelo</label>
                                                    <div class="form-group">
                                                        <input type="text" value={modelo} id="company" class="form-control" onChange={handleModelo} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Marca</label>
                                                    <div class="form-group">
                                                        <input type="text" value={marca} id="company" class="form-control" onChange={handleMarca} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Cor</label>
                                                    <div class="form-group">
                                                        <input type="text" value={cor} id="company" class="form-control" onChange={handleCor} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>Placa</label>
                                                    <div class="form-group">
                                                        <input type="text" maxLength={7} value={placa} id="company" class="form-control" onChange={handlePlaca} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Status</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleStatus} >
                                                        <option value="">Ativo ou Inativo </option>
                                                        <option value="0" selected={cargueiro.status === 0 ? "selected" : null}>Inativo </option>
                                                        <option value="1" selected={cargueiro.status === 1 ? "selected" : null}>Ativo </option>
                                                    </select>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Escolha a filial</label>
                                                    <select name="select" id="select" class="form-control" onChange={handleAlocacao} >
                                                        <option value="">Escolha a Filial </option>
                                                        {filiais.map((filial) =>
                                                            <option value={filial.id} selected={cargueiro.filial === filial.id ? "selected" : null}>{filial.nome} - ({filial.endereco.cidade}/{filial.endereco.uf})</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="page-header col-md-3" style={{ marginTop: 20 }}>
                                                {loading ?
                                                    <button type="button" class="btn btn-default mb-1" >
                                                        Atualizando cargueiro ...
                                                    </button>
                                                    :
                                                    <button type="button" class="btn btn-success mb-1" onClick={() => cadastraFilial()}>
                                                        Atualizar Cargueiro
                                                </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Header />
            </div>
        </div>

    );
}
