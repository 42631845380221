import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { verificaAutenticado } from '../../services/firebase'
import firebase from 'firebase'

export default function Login() {
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [loading, setLoading] = useState(false);
    const [erros, setErro] = useState({
        email: '',
        senha: '',
        erro: ''
    });

    useEffect(() => {
        verificaAutenticado('login')
    }, [])

    function realizaLogin() {
        if (email === '' && senha === '') {
            setErro({
                erro: 'Email e senha não podem ser vazios'
            })
            setLoading(false)
        }
        else if (email === '') {
            setErro({
                email: 'Email vazio'
            })
            setLoading(false)
        } else if (senha === '') {
            setErro({
                senha: 'Senha vazia'
            })
            setLoading(false)
        } else {
            setErro({})
            setLoading(true)
            // login(email, senha)
            firebase.auth().signInWithEmailAndPassword(email, senha).then(() => {
                firebase.auth().onAuthStateChanged((usuario) => {
                    firebase.firestore().collection('usuarios').doc(usuario.uid).get().then((doc) => {
                        if (doc.data().usuario === 1) {
                            window.location = '/administrador'
                        } else {
                            firebase.auth().signOut().then(() => {
                                alert('Usuário não autorizado para acesso! Se você é aluno, acesse o App')
                            })
                        }
                    })
                });


            }).catch((error) => {
                // Handle Errors here.
                var errorCode = error.code;

                if (error) {
                    if (errorCode === 'auth/invalid-email') {
                        setErro({
                            erro: 'Email incompleto ou não é válido'
                        })
                        setLoading(false)
                    } else if (errorCode === 'auth/user-not-found') {
                        setErro({
                            erro: 'Email não cadastrado em nosso App! Cadastre-se ou verifique novamente'
                        })
                        setLoading(false)
                    } else if (errorCode === 'auth/wrong-password') {
                        setErro({
                            erro: 'Senha Incorreta'
                        })
                        setLoading(false)
                    }
                }
            });
        }

    }

    const handleInputEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleInputSenha = (event) => {
        event.persist();
        setSenha(event.target.value);
    }

    return (
        <div className="limiter">
            <div className="container-login100">
                <div className="login100-more" >
                    <img src='https://www.clicklogtransportes.com.br/wp-content/uploads/2018/06/transporte-de-cargas.png' alt="" />
                </div>
                <div className="wrap-login100 p-l-50 p-r-50 p-t-72 p-b-50">
                    <span className="login100-form-title p-b-19">Login</span>
                    {erros.erro ?
                        <span className="login90-form-title" style={{ color: 'red' }} >{erros.erro}</span>
                        :
                        null
                    }
                    {erros.email ?
                        <span className="login90-form-title" style={{ color: 'red' }} >{erros.email}</span>
                        :
                        null
                    }
                    {erros.senha ?
                        <span className="login90-form-title" style={{ color: 'red' }} >{erros.senha}</span>
                        :
                        null
                    }

                    <div className="wrap-input100 validate-input" data-validate="Name is required" style={{ marginTop: 20 }} >
                        <input className="input100" value={email} autoCapitalize="none" onChange={handleInputEmail} type="text" name="name" placeholder="Email" />
                        <span className="focus-input100"></span>
                    </div>
                    <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                        <input className="input100" value={senha} autoCapitalize="none" onChange={handleInputSenha} type="password" name="email" placeholder="Senha" />
                        <span className="focus-input100"></span>
                    </div>

                    <div className="container-login100-form-btn">
                        <div className="wrap-login100-form-btn">
                            <div className="login100-form-bgbtn"></div>
                            {loading ?
                                <button className="login100-form-btn" style={{ backgroundColor: 'red' }} >Aguarde...</button>
                                :
                                <button className="login100-form-btn" style={{ backgroundColor: 'red' }} onClick={realizaLogin}>Login</button>
                            }
                        </div>
                        {/* <Link to="/cadastro" className="dis-block txt3 hov1 p-r-30 p-t-10 p-b-10 p-l-30">Cadastrar<i className="fa fa-long-arrow-right m-l-5"></i>
                        </Link> */}
                    </div>
                </div>
            </div>
        </div>
    );
}
