import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import firebase from 'firebase'
import { logout, verificaAutenticado } from '../../../services/firebase'

export default function Header() {

    const [usuario, setUsuario] = useState({});    

    useEffect(() => {
        verificaAutenticado('header')
        firebase.auth().onAuthStateChanged((user) => {
            firebase.firestore().collection('usuarios').doc(user.uid).get().then((doc) => {
                setUsuario(doc.data());                
            })
        })
    }, []);

    function sair() {
        logout();
    }

    return (
        <header id="header" className="header">
            <div className="top-left">
                <div className="navbar-header">
                    <Link className="navbar-brand" to="/">Carga Já</Link>
                    <a href="#" id="menuToggle" className="menutoggle"><i className="fa fa-bars"></i></a>
                </div>
            </div>
            <div className="top-right">
                <div className="header-menu">

                    <div className="user-area dropdown float-right">
                        <Link to="#" className="dropdown-toggle active" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <img className="user-avatar rounded-circle" src={usuario.avatar} alt="" />
                        </Link>

                        <div className="user-menu dropdown-menu">
                            <Link className="nav-link" to="#"><i className="fa fa-user"></i>Perfil</Link>
                            <Link className="nav-link" to="#"><i className="fa fa-cog"></i>Configurações</Link>
                            <Link className="nav-link" to="#" onClick={sair} ><i className="fa fa-power-off"></i>Sair</Link>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
