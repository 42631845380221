import React, { useEffect, useState } from 'react';
import Header from '../componentes/Header'
import Sidebar from '../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'

export default function GalpoesClientes(props) {
    const [cliente] = useState(props.location.state.cliente)
    const [galpoes, setGalpoes] = useState([])
    const [servicos] = useState({
        auth: firebase.auth().currentUser,
        galpoes: firebase.firestore().collection('clientes').doc(cliente.id).collection('galpoes')
    })
    //Dados para cadastro e edição do Galpão
    const [id, setId] = useState('')
    const [nome, setNome] = useState('')
    const [descricao, setDescricao] = useState('')

    const [erro, setErro] = useState('')
    const [sucesso, setSucesso] = useState('')

    const [edit, setEdit] = useState(false)

    //Plataformas
    const [galpao, setGalpao] = useState('')
    const [idGalpao, setIdGalpao] = useState('')
    const [plataforma, setPlataforma] = useState('')
    const [plataformas, setPlataformas] = useState([])

    useEffect(() => {
        servicos.galpoes.onSnapshot((snap) => {
            const data = snap.docs.map(doc => doc.data())
            setGalpoes(data)
        })
    }, [servicos.galpoes])

    function cadastraGalpao() {
        setErro("")
        setSucesso("")
        if (nome === '') {
            setErro('Favor digite o nome do Galpão')
        } else if (descricao === '') {
            setErro('Digite uma descrição para identificar melhor o Galpão')
        } else {
            if (edit) {
                servicos.galpoes.doc(id).update({
                    nome: nome,
                    descricao: descricao
                }).then(() => {
                    setSucesso('Galpão atualizado com sucesso')
                    descartaDados()
                }).catch(() => {
                    setErro('Erro ao atualizar galpão')
                })
            } else {
                servicos.galpoes.add({
                    nome: nome,
                    descricao: descricao,
                    cliente: cliente.id,
                }).then((docRef) => {
                    servicos.galpoes.doc(docRef.id).update({
                        id: docRef.id
                    }).then(() => {
                        setSucesso('Galpão cadastrado com sucesso')
                        descartaDados()
                    }).catch(() => {
                        setErro('Erro ao atualizar galpão')
                    })
                }).catch(() => {
                    setErro('Erro ao cadastrar galpão')
                })
            }
        }
    }

    function carregaDados(id, nome, descricao) {
        setEdit(true)
        setId(id)
        setNome(nome)
        setDescricao(descricao)
    }
    function descartaDados() {
        setEdit(false)
        setId('')
        setNome('')
        setDescricao('')
    }

    function removeGalpao(id, nome) {
        setErro("")
        setSucesso("")
        var r = window.confirm("Deseja excluir o galpão " + nome + " ?\n Ao remover você estará removendo também as plataformas!\n Deseja Prosseguir?");
        if (r == true) {
            servicos.galpoes.doc(id).collection("plataformas").onSnapshot((snap) => {
                snap.forEach((doc) => {
                    servicos.galpoes.doc(id).collection("plataformas").doc(doc.data().id).delete();
                })
            })
            servicos.galpoes.doc(id).delete().then(() => {
                setSucesso("Galpão removido com sucesso!!")
            }).catch(() => {
                setErro("Erro ao remover o Glapão")
            })
        } else {

        }
    }

    function abreModal(id, galpao) {
        setIdGalpao(id)
        setGalpao(galpao)
        servicos.galpoes.doc(id).collection("plataformas").onSnapshot((snap) => {
            const data = snap.docs.map(doc => doc.data())
            setPlataformas(data)
        })
    }
    function cadastraPlataforma() {
        if (plataforma === '') {
            setErro('Favor digite o nome da Plataforma')
        } else {
            servicos.galpoes.doc(idGalpao).collection("plataformas").add({
                plataforma: plataforma,
                cliente: cliente.id,
                galpao: idGalpao
            }).then((docRef) => {
                servicos.galpoes.doc(idGalpao).collection("plataformas").doc(docRef.id).update({
                    id: docRef.id
                })
            }).catch(() => {
                setErro('Erro ao cadastrar galpão')
            })
        }
    }
    function removePlataforma(id) {
        var r = window.confirm("Deseja excluir a plataforma ");
        if (r == true) {
            servicos.galpoes.doc(idGalpao).collection("plataformas").doc(id).delete()
        }
    }

    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleDescricao = (event) => {
        event.persist();
        setDescricao(event.target.value);
    }
    const handlePlataforma = (event) => {
        event.persist();
        setPlataforma(event.target.value);
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div class="content">
                    <div class="modal fade" id="modalGalpao" tabindex="-1" role="dialog" aria-labelledby="staticModalLabel"
                        aria-hidden="true">
                        <div class="modal-dialog modal-md" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="staticModalLabel">Plataformas {galpao}</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="row m-0">
                                        <div className='col-md-9'>
                                            <label>Nome da Plataforma</label>
                                            <div class="form-group">
                                                <input type="text" value={plataforma} id="company" class="form-control" onChange={handlePlataforma} />
                                            </div>
                                        </div>
                                        <div className='col-md-2' style={{ marginTop: 30 }}>
                                            <button type="button" onClick={() => cadastraPlataforma()} class="btn btn-success mb-1" >Cadastrar</button>
                                        </div>
                                    </div>
                                    <div className="table-stats order-table ov-h">
                                        <table className="table ">
                                            <thead>
                                                <tr>
                                                    <th>Nome</th>
                                                    <th>Excluir</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {plataformas.map((plataforma) =>
                                                    <tr key={plataforma.id} >
                                                        <td>{plataforma.plataforma}</td>
                                                        <td>
                                                            <span onClick={() => removePlataforma(plataforma.id, plataforma.nome)} className="badge badge-danger"><i className='fa fa-trash'></i></span>
                                                        </td>
                                                    </tr>
                                                )}

                                            </tbody>
                                        </table>
                                        {plataformas.length === 0 ?
                                            <p style={{ textAlign: 'center' }} >Sem plataformas cadastradas</p>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <div class="col-sm-10">
                                                    <h4 className="box-title">Galpões</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className='col-md-12'>
                                                {sucesso ?
                                                    <div class="alert alert-success" role="alert">{sucesso}</div>
                                                    :
                                                    null
                                                }
                                                {erro ?
                                                    <div class="alert alert-danger" role="alert">{erro}</div>
                                                    :
                                                    null
                                                }
                                            </div>
                                            {edit ?
                                                <div class="row m-0">
                                                    <div className='col-md-3'>
                                                        <label>Nome do Galpão</label>
                                                        <div class="form-group">
                                                            <input type="text" value={nome} id="company" class="form-control" onChange={handleNome} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label>Descrição</label>
                                                        <div class="form-group">
                                                            <input type="text" value={descricao} id="company" class="form-control" onChange={handleDescricao} />
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-2" style={{ marginTop: 30 }}>
                                                        <button type="button" onClick={() => cadastraGalpao()} class="btn btn-success mb-1" >Atualizar Galpão</button>
                                                    </div>
                                                    <div class="col-sm-2">
                                                        <button type="button" onClick={() => descartaDados()} class="btn btn-danger mb-1" >Descartar</button>
                                                    </div>
                                                </div>
                                                :
                                                <div class="row m-0">
                                                    <div className='col-md-3'>
                                                        <label>Nome do Galpão</label>
                                                        <div class="form-group">
                                                            <input type="text" value={nome} id="company" class="form-control" onChange={handleNome} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-7'>
                                                        <label>Descrição</label>
                                                        <div class="form-group">
                                                            <input type="text" value={descricao} id="company" class="form-control" onChange={handleDescricao} />
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-2" style={{ marginTop: 30 }}>
                                                        <button type="button" onClick={() => cadastraGalpao()} class="btn btn-success mb-1" >Cadastrar Galpão</button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Nome</th>
                                                            <th>Descrição</th>
                                                            <th>Plataformas</th>
                                                            <th>Editar</th>
                                                            <th>Excluir</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {galpoes.map((galpao) =>
                                                            <tr key={galpao.id} >
                                                                <td>{galpao.nome}</td>
                                                                <td>{galpao.descricao}</td>
                                                                <td>
                                                                    <span onClick={() => abreModal(galpao.id, galpao.nome)} className="badge badge-info" data-toggle="modal" data-target="#modalGalpao"><i className='fa fa-search'></i></span>
                                                                </td>
                                                                <td>
                                                                    <span onClick={() => carregaDados(galpao.id, galpao.nome, galpao.descricao)} className="badge badge-warning"><i className='fa fa-edit'></i></span>
                                                                </td>
                                                                <td>
                                                                    <span onClick={() => removeGalpao(galpao.id, galpao.nome)} className="badge badge-danger"><i className='fa fa-trash'></i></span>
                                                                </td>
                                                            </tr>
                                                        )}

                                                    </tbody>
                                                </table>
                                                {galpoes.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem galpões cadastradas</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
