// Carrega UF
export const carregaEstados = () => {
    const URL = "https://servicodados.ibge.gov.br/api/v1/localidades/estados";
    var headers = new Headers();
    return fetch(URL, {
        headers: headers
    }).then((response) => response.json());
}

// Carrega Cidades
export const carregaCidade = (uf) => {
    const URL = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`;
    var headers = new Headers();
    return fetch(URL, {
        headers: headers
    }).then((response) => response.json());
}

//Busca cep
export const buscaEndereco = (cep) => {
    const URL = `https://viacep.com.br/ws/${cep}/json/`;
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
    }).then((response) => response.json());
}