import React from 'react';
import Routes from './Routes'
import firebase from 'firebase'

const firebaseConfig = {
  apiKey: "AIzaSyDl4jNBGla2-aEKwig6kIEGtmIUQEZw7h4",
  authDomain: "carga-ja.firebaseapp.com",
  databaseURL: "https://carga-ja.firebaseio.com",
  projectId: "carga-ja",
  storageBucket: "carga-ja.appspot.com",
  messagingSenderId: "10445390546",
  appId: "1:10445390546:web:7e0bf6f11e1476d57aa30b",
  measurementId: "G-Q7937E5Z2H"
};
firebase.initializeApp(firebaseConfig);

function App() {
  return (
    <div className="App">
      <Routes />
    </div>
  );
}

export default App;
