import React, { useEffect, useState } from 'react';
import Header from '../componentes/Header'
import Sidebar from '../componentes/Sidebar'
import firebase from 'firebase'
import { buscaEndereco } from '../../../services/WsConnect'
import moment from 'moment'
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
export default function CadastraCliente() {

    const [servicos] = useState({
        clientes: firebase.firestore().collection('clientes'),
        usuarios: firebase.firestore().collection('usuarios'),
    })
    const [usuario, setUsuario] = useState({})

    const [nome, setNome] = useState('')
    const [cep, setCep] = useState('')
    const [endereco, setEndereco] = useState('')
    const [bairro, setBairro] = useState('')
    const [numero, setNumero] = useState('')
    const [complemento, setComplemento] = useState('')
    const [uf, setUf] = useState('')
    const [cidade, setCidade] = useState('')
    const [descricao, setDescricao] = useState('')

    var metadata = { contentType: 'image/jpeg' };
    const [avatar, setAvatar] = useState('');
    const [upload, setUpload] = useState('');
    const [progresso, setProgresso] = useState('')

    const [sucesso, setSucesso] = useState('');
    const [erro, setErro] = useState('');

    const [loading, setLoading] = useState(false)
    const [loadingImage, setLoadingImage] = useState(false)
    const [loadingCep, setLoadingCep] = useState(false)


    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            servicos.usuarios.doc(user.uid).get().then((doc) => {
                setUsuario(doc.data());
            })
        })
    }, [servicos.clientes])

    function removeMensagem() {
        setTimeout(() => {
            setLoading(false)
            setSucesso('')
            setErro('')
            window.location = '/clientes'
        }, 3000)
    }

    function cadastraAvatar(id) {
        setLoadingImage(true)
        var uploadTask = firebase.storage().ref().child('clientes/' + id).put(avatar[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgresso(parseInt(progress) + '%')
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    setLoadingImage(false)
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.clientes.doc(id).update({
                    avatar: downloadURL
                }).then(() => {
                    setSucesso('Cliente cadastrado com sucesso! Você será redirecionado a lista de Clientes')
                    setLoadingImage(false)
                    removeMensagem()
                })
            });
        });
    }

    function cadastraCliente() {
        setLoading(true)
        setErro('')
        if (nome === '') {
            setErro('Favor digitar o nome do Cliente')
            setLoading(false)
        } else if (cep === '') {
            setErro('Favor digitar o Cep')
            setLoading(false)
        } else if (endereco === '') {
            setErro('Favor digitar o Endereço')
            setLoading(false)
        } else if (bairro === '') {
            setErro('Favor digite o Bairro')
            setLoading(false)
        } else if (complemento === '') {
            setErro('Favor digite o Complemento')
            setLoading(false)
        } else if (numero === '') {
            setErro('Favor digite o número')
            setLoading(false)
        } else {
            servicos.clientes.add({
                nome: nome,
                endereco: {
                    endereco: endereco,
                    bairro: bairro,
                    numero: numero,
                    complemento: complemento,
                    uf: uf,
                    cidade: cidade,
                },
                descricao: descricao,
                status: parseInt(0),
                usuario: {
                    id: usuario.id,
                    nome: usuario.nome
                },
                dataAtualizacao: moment().format('DD/MM/YYYY HH:mm')
            }).then((docRef) => {
                servicos.clientes.doc(docRef.id).update({
                    id: docRef.id
                }).then(() => {
                    cadastraAvatar(docRef.id)
                }).catch(() => {
                    setErro('Erro ao atualizar o cliente')
                })
            }).catch(() => {
                setErro('Erro ao cadastrar o Cliente')
            })
        }
    }

    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleDescricao = (event) => {
        setDescricao(event);
    }
    const handleCep = (event) => {
        event.persist();
        setCep(event.target.value);
    }
    const handleEndereco = (event) => {
        event.persist();
        setEndereco(event.target.value);
    }
    const handleBirro = (event) => {
        event.persist();
        setBairro(event.target.value);
    }
    const handleComplemento = (event) => {
        event.persist();
        setComplemento(event.target.value);
    }
    const handleNumero = (event) => {
        event.persist();
        setNumero(event.target.value);
    }
    const handleCidade = (event) => {
        event.persist();
        setCidade(event.target.value);
    }
    const handleUf = (event) => {
        event.persist();
        setUf(event.target.value);
    }
    const handleAvatar = (event) => {
        event.persist();
        setAvatar(event.target.files)
        setUpload(URL.createObjectURL(event.target.files[0]))
    }

    function buscaCep() {
        setLoadingCep(true)
        if (cep.length < 8) {
            setLoadingCep(false)
            alert('Favor digite o CEP para realizar a busca')
        } else {
            buscaEndereco(cep).then((doc) => {
                if (doc.erro === true) {
                    setLoadingCep(false)
                    alert('Não achamos seu CEP! Favor verifique novamente ou digite manualmente')
                } else {
                    setEndereco(doc.logradouro)
                    setBairro(doc.bairro)
                    setCidade(doc.localidade)
                    setUf(doc.uf)
                    setLoadingCep(false)
                }
            })
        }
    }

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <div className="content">
                    {erro ?
                        <div class="alert alert-danger" role="alert">{erro}</div>
                        :
                        null
                    }
                    {sucesso ?
                        <div class="alert alert-success" role="alert">{sucesso}</div>
                        :
                        null
                    }
                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <h4 className="box-title">Novo Cliente</h4>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className='col-md-12 row' >
                                                <div className='col-md-4'>
                                                    {upload ?
                                                        <img class="card-img-top" style={{ height: 300, width: 300 }} src={upload} alt='carregando...' />
                                                        :
                                                        <img class="card-img-top" style={{ height: 300, width: 300 }} src='https://www.pngitem.com/pimgs/m/315-3153392_transparent-cliente-png-png-download.png' alt='carregando...' />
                                                    }

                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Mudar a imagem do Cliente</label>
                                                    <p>Para mudar a foto do cliente é só carregar uma nova imagem e enviar. Automaticamente ele já estará salvo</p>

                                                    {loadingImage ?
                                                        <div class="progress-box progress-1" style={{ marginTop: 10 }}>
                                                            <div class="por-txt">Carregando... ({progresso})</div>
                                                            <div class="progress mb-2" >
                                                                <div class="progress-bar bg-flat-color-1" role="progressbar" style={{ width: progresso }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <input onChange={handleAvatar} type="file" name="name" placeholder="Escolha a imagem " />
                                                    }

                                                </div>
                                            </div>

                                            <div className='row col-md-12' style={{ marginTop: 20 }}>
                                                <div className='col-md-6'>
                                                    <label>Nome do Cliente</label>
                                                    <div class="form-group">
                                                        <input type="text" value={nome} id="company" class="form-control" onChange={handleNome} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row col-md-12'>
                                                <div className='col-md-3'>
                                                    <label>Cep</label>
                                                    <div class="form-group">
                                                        <input type="number" value={cep} id="company" class="form-control" onChange={handleCep} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2' style={{ marginTop: 30 }}>
                                                    {loadingCep ?
                                                        <button type="button" class="btn btn-info mb-1" >Buscando...</button>
                                                        :
                                                        <button type="button" onClick={() => buscaCep()} class="btn btn-success mb-1" >Buscar CEP</button>
                                                    }

                                                </div>
                                                <div className='col-md-7'>
                                                    <label>Endereço</label>
                                                    <div class="form-group">
                                                        <input type="text" value={endereco} id="company" class="form-control" onChange={handleEndereco} />
                                                    </div>
                                                </div>
                                                <div className='col-md-5'>
                                                    <label>Bairro</label>
                                                    <div class="form-group">
                                                        <input type="text" value={bairro} id="company" class="form-control" onChange={handleBirro} />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label>Cidade</label>
                                                    <div class="form-group">
                                                        <input type="text" value={cidade} id="company" class="form-control" onChange={handleCidade} />
                                                    </div>
                                                </div>
                                                <div className='col-md-1'>
                                                    <label>UF</label>
                                                    <div class="form-group">
                                                        <input type="text" value={uf} id="company" class="form-control" onChange={handleUf} />
                                                    </div>
                                                </div>
                                                <div className='col-md-10'>
                                                    <label>Complemento</label>
                                                    <div class="form-group">
                                                        <input type="text" value={complemento} id="company" class="form-control" onChange={handleComplemento} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label>N°</label>
                                                    <div class="form-group">
                                                        <input type="number" value={numero} id="company" class="form-control" onChange={handleNumero} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12'>
                                                <label>Sobre o Cliente</label>
                                                <SunEditor
                                                    lang="pt_br"
                                                    placeholder="Digite a descriçao do Cliente"
                                                    onChange={handleDescricao}
                                                    setContents={descricao}
                                                />
                                            </div>
                                            <div class="page-header col-md-3" style={{ marginTop: 20 }}>
                                                {loading ?
                                                    <button type="button" class="btn btn-default mb-1" >
                                                        Cadastrando cliente ...
                                                    </button>
                                                    :
                                                    <button type="button" class="btn btn-success mb-1" onClick={() => cadastraCliente()}>
                                                        Cadastra Cliente
                                                </button>
                                                }
                                            </div>
                                            <div className='col-md-12'>

                                                {sucesso ?
                                                    <div class="alert alert-success" role="alert">{sucesso}</div>
                                                    :
                                                    null
                                                }
                                                {erro ?
                                                    <div class="alert alert-danger" role="alert">{erro}</div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Header />
            </div>
        </div>

    );
}
