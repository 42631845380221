import React, { useEffect, useState } from 'react';
import Header from '../componentes/Header'
import Sidebar from '../componentes/Sidebar'
import firebase from 'firebase'
import { Link } from 'react-router-dom'

export default function Clientes() {

    const [motoristas, setMotoristas] = useState([])
    const [servicos] = useState({
        auth: firebase.auth().currentUser,
        motoristas: firebase.firestore().collection('motoristas')
    })

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            servicos.motoristas.where('admin.id', '==', user.uid).onSnapshot((snap) => {
                const data = snap.docs.map(doc => doc.data())
                setMotoristas(data)
            })
        })
    }, [servicos.motoristas])

    return (
        <div>
            <Sidebar />
            <div id="right-panel" className="right-panel">
                <Header />
                <div class="content">

                    <div className="animated fadeIn">
                        <div className="orders">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row m-0">
                                                <div class="col-sm-10">
                                                    <h4 className="box-title">Motoristas</h4>
                                                </div>
                                                <div class="col-sm-2">
                                                    <Link to='/cadastroMotorista'>
                                                        <button type="button" class="btn btn-success mb-1" >Novo Motorista</button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body--">
                                            <div className="table-stats order-table ov-h">
                                                <table className="table ">
                                                    <thead>
                                                        <tr>
                                                            <th>Avatar</th>
                                                            <th>Nome</th>
                                                            <th>Contato</th>
                                                            <th>Cidade</th>
                                                            <th>UF</th>
                                                            <th>Status</th>
                                                            <th>Detalhe</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {motoristas.map((motorista) =>
                                                            <tr key={motorista.id} >
                                                                <td class="avatar">
                                                                    <div class="round-img">
                                                                        <img class="rounded-circle" src={motorista.avatar} alt="" />
                                                                    </div>
                                                                </td>
                                                                <td>{motorista.nome}</td>
                                                                <td>{motorista.contato}</td>
                                                                <td>{motorista.endereco.cidade}</td>
                                                                <td>{motorista.endereco.uf}</td>
                                                                {motorista.status === 0 ?
                                                                    <td>
                                                                        <span className="badge badge-danger">Inativo</span>
                                                                    </td>
                                                                    :
                                                                    <td>
                                                                        <span className="badge badge-success">Ativo</span>
                                                                    </td>
                                                                }
                                                                <td>
                                                                    <Link to={{ pathname: '/detalheMotorista', state: { motorista: motorista } }}>
                                                                        <span className="badge badge-info"><i className='fa fa-search'></i></span>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}

                                                    </tbody>
                                                </table>
                                                {motoristas.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} >Sem Motoristas cadastrados</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
