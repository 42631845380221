import React from 'react';
import { Link } from 'react-router-dom'

export default function Sidebar() {
    return (
        <aside id="left-panel" className="left-panel">
            <nav className="navbar navbar-expand-sm navbar-default">

                <div id="main-menu" className="main-menu collapse navbar-collapse">
                    <ul className="nav navbar-nav">
                        <li>
                            <Link to="/administrador"><i className="menu-icon fa fa-dashboard"></i>Dashboard </Link>
                        </li>
                        <li className="menu-title">Serviços</li>
                        <li>
                            <Link to="/clientes"><i className="menu-icon fa fa-handshake-o"></i>Clientes </Link>
                        </li>
                        <li>
                            <Link to="/filiais"><i className="menu-icon fa fa-cubes"></i>Filiais </Link>
                        </li>
                        <li>
                            <Link to="/motoristas"><i className="menu-icon fa fa-truck"></i>Motoristas </Link>
                        </li>
                        <li>
                            <Link to="/cargueiros"><i className="menu-icon fa fa-car"></i>Cargueiros </Link>
                        </li>
                        <li>
                            <Link to="/conferentes"><i className="menu-icon fa fa-id-card-o "></i>Conferentes </Link>
                        </li>
                        <li>
                            <Link to="/servicos"><i className="menu-icon fa fa-exchange"></i>Serviços </Link>
                        </li>
                        <li className="menu-title">Escritório</li>
                        <li>
                            <Link to="/agenda"><i className="menu-icon fa fa-address-book"></i>Agenda </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </aside>
    );
}
